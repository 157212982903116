import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { LanguageLocale, NodeLocale } from "@components/Shared/model/LanguageContent";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Badge } from "@ryerson/frontend.notification";
import { Icon } from "@ryerson/frontend.assets";
import { Breadcrumb, Link } from "@ryerson/frontend.navigation";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import SocialMedia from "@components/NewsEvents/SocialMedia/SocialMedia";
import styled from "@emotion/styled";
import { renderBlogRichText } from "@components/Shared/model/Contentful";
import { PopularContent } from "./PopularContent";
import { css } from "@emotion/react";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

export type ContenfulBlogNode = {
	body: any;
	category: BlogCategories;
	publishDate: string;
	seoDescription: any;
	seoImage?: any;
	seoKeywords?: string;
	heroImage: any;
	seoTitle?: string;
	slug: string;
	subTitle: any;
	title: string;
	node_locale: NodeLocale;
};

export type StaticBlogContent = {
	popularHeading: string;
	ryersonGuideTitle: string;
	ryersonGuideSubTitle: string;
	ryersonGuideUrl: string;
};

export type ContentfulNode = {
	node: ContenfulBlogNode;
};

export type BlogProps = {
	contentfulContent: ContentfulNode[];
	content: StaticBlogContent;
};

export type BlogCategories =
	| "All Content"
	| "Market Updates"
	| "Grade Anatomy"
	| "Metal Processing"
	| "Industry Trends"
	| "Metal Perspective"
	| "Success Stories";

export type BlogURLCategories =
	| ""
	| "market-updates"
	| "grade-anatomy"
	| "metal-processing"
	| "industry-trends"
	| "metal-perspective"
	| "success-stories";

export type BlogCategory = {
	label: string;
	url: BlogURLCategories;
	title: string;
	imageUrl: string;
	description: string;
	backgroundImageUrl: string;
	backgroundPosition: "cover" | "top-right";
};

export const blogCategories: LanguageContent<BlogCategory[]> = {
	en: [
		{
			label: "All Content",
			title: "Metal Market Intelligence",
			url: "",
			imageUrl: "/images/metal-resources/allContentThumbnail.jpg",
			description:
				"Which direction are metal prices headed and why? Check out what our professionals have to say about the latest trends.",
			backgroundPosition: "cover",
			backgroundImageUrl: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		},
		{
			label: "Market Updates",
			url: "market-updates",
			title: "Market Updates",
			imageUrl: "/images/metal-resources/marketUpdatesThumbnailUpdate.jpg",
			description:
				"The Monthly Market Report combines market intelligence and Ryerson expertise.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MarketUpdatesUpdate.jpg",
		},
		{
			label: "Grade Anatomy",
			url: "grade-anatomy",
			title: "Grade Anatomy",
			imageUrl: "/images/metal-resources/gradeAnatomyThumbnail.jpg",
			description: "Inside the chemistry that makes up some of the most common metal grades.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/GradeAnatomy.jpg",
		},
		{
			label: "Metal Processing",
			url: "metal-processing",
			title: "Metal Processing",
			imageUrl: "/images/metal-resources/metalProcessingThumbnailUpdate.jpg",
			description:
				"An in-depth look at metal fabrication processes, scenarios, and outcomes.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MetalProcessing.jpg",
		},
		{
			label: "Industry Trends",
			url: "industry-trends",
			title: "Industry Trends",
			imageUrl: "/images/metal-resources/industryTrendsThumbnail.jpg",
			description:
				"What's moving the market right now? From pricing to availability, unique perspectives on metal trends.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/IndustryTrends.jpg",
		},
		{
			label: "Metal Perspective",
			url: "metal-perspective",
			title: "Metal Perspective",
			imageUrl: "/images/metal-resources/metalPerspectiveThumbnailUpdate.jpg",
			description:
				"Ryerson customers respond in this twice-annual survey on market conditions.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MetalPerspectiveUpdate2.jpg",
		},
		{
			label: "Success Stories",
			url: "success-stories",
			title: "Success Stories",
			imageUrl: "/images/metal-resources/successStoriesThumbnailUpdate.jpg",
			description:
				"Take a look at how we have helped customers tackle some of the most pressing market challenges with results.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/SuccessStoriesUpdate.jpg",
		},
	],
	fr: [
		{
			label: "Tout le contenu",
			title: "Information sur le marché du métal",
			url: "",
			imageUrl: "/images/metal-resources/allContentThumbnail.jpg",
			description:
				"Dans quelle direction vont les prix des métaux? Et pourquoi? Découvrez ce que nos experts ont à dire sur les dernières tendances.",
			backgroundPosition: "cover",
			backgroundImageUrl: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		},
		{
			label: "Mises à jour du marché ",
			url: "market-updates",
			title: "Mises à jour du marché ",
			imageUrl: "/images/metal-resources/marketUpdatesThumbnailUpdate.jpg",
			description:
				"Le rapport mensuel sur le marché combine l'information sur le marché et l'expertise de Ryerson.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MarketUpdatesUpdate.jpg",
		},
		{
			label: "Anatomie des grades",
			url: "grade-anatomy",
			title: "Anatomie des grades",
			imageUrl: "/images/metal-resources/gradeAnatomyThumbnail.jpg",
			description:
				"À l'intérieur de la chimie qui compose certaines des nuances de métaux les plus courantes.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/GradeAnatomy.jpg",
		},
		{
			label: "Traitement des métaux",
			url: "metal-processing",
			title: "Traitement des métaux",
			imageUrl: "/images/metal-resources/metalProcessingThumbnailUpdate.jpg",
			description:
				"Un examen approfondi des processus de fabrication des métaux, des scénarios et des résultats.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MetalProcessing.jpg",
		},
		{
			label: "Tendances du secteur",
			url: "industry-trends",
			title: "Tendances du secteur",
			imageUrl: "/images/metal-resources/industryTrendsThumbnail.jpg",
			description:
				"Qu'est-ce qui fait bouger le marché en ce moment ? Du prix à la disponibilité, des perspectives uniques sur les tendances des métaux.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/IndustryTrends.jpg",
		},
		{
			label: "Perspectives sur les métaux",
			url: "metal-perspective",
			title: "Perspectives sur les métaux",
			imageUrl: "/images/metal-resources/metalPerspectiveThumbnailUpdate.jpg",
			description:
				"Les clients de Ryerson répondent à ce sondage semestriel sur les conditions du marché.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MetalPerspectiveUpdate.jpg",
		},
		{
			label: "Témoignages de réussite",
			url: "success-stories",
			title: "Témoignages de réussite",
			imageUrl: "/images/metal-resources/successStoriesThumbnailUpdate.jpg",
			description:
				"Découvrez comment nous avons aidé nos clients à relever certains des défis les plus pressants du marché avec des résultats.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/SuccessStoriesUpdate.jpg",
		},
	],
	es: [
		{
			label: "Todo el contenido",
			title: "Información del mercado de metales:",
			url: "",
			imageUrl: "/images/metal-resources/allContentThumbnail.jpg",
			description:
				"¿A dónde se dirigen los precios de metal y por qué? Vea lo que nuestros expertos han dicho acerca de las últimas tendencias.",
			backgroundPosition: "cover",
			backgroundImageUrl: "/images/metal-resources/metalMarketIntelligenceUpdate2.jpg",
		},
		{
			label: "Actualizaciones del mercado ",
			url: "market-updates",
			title: "Actualizaciones del mercado ",
			imageUrl: "/images/metal-resources/marketUpdatesThumbnailUpdate.jpg",
			description:
				"El Informe de mercado mensual combina la inteligencia de mercado y la experiencia de Ryerson.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MarketUpdatesUpdate.jpg",
		},
		{
			label: "Anatomía del grado",
			url: "grade-anatomy",
			title: "Anatomía del grado",
			imageUrl: "/images/metal-resources/gradeAnatomyThumbnail.jpg",
			description:
				"Dentro de la química que compone algunos de los grados de metal más comunes.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/GradeAnatomy.jpg",
		},
		{
			label: "Procesamiento de metales",
			url: "metal-processing",
			title: "Procesamiento de metales",
			imageUrl: "/images/metal-resources/metalProcessingThumbnailUpdate.jpg",
			description:
				"Una mirada en profundidad a los procesos, escenarios y resultados de fabricación de metal.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MetalProcessing.jpg",
		},
		{
			label: "Tendencias de la industria",
			url: "industry-trends",
			title: "Tendencias de la industria",
			imageUrl: "/images/metal-resources/industryTrendsThumbnail.jpg",
			description:
				"¿Qué está moviendo el mercado en este momento? Desde el precio hasta la disponibilidad, perspectivas únicas sobre las tendencias de los metales.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/IndustryTrends.jpg",
		},
		{
			label: "Perspectiva de metales",
			url: "metal-perspective",
			title: "Perspectiva de metales",
			imageUrl: "/images/metal-resources/metalPerspectiveThumbnailUpdate.jpg",
			description:
				"Los clientes de Ryerson responden en esta encuesta semestral sobre las condiciones del mercado.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/MetalPerspectiveUpdate.jpg",
		},
		{
			label: "Casos de éxito",
			url: "success-stories",
			title: "Casos de éxito",
			imageUrl: "/images/metal-resources/successStoriesThumbnailUpdate.jpg",
			description:
				"Eche un vistazo a cómo hemos ayudado a los clientes a enfrentar algunos de los desafíos más apremiantes del mercado con resultados.",
			backgroundPosition: "cover",
			backgroundImageUrl:
				"/images/metal-resources/metal-market-intelligence/SuccessStoriesUpdate.jpg",
		},
	],
};

export const TranslateBlogCategory = (selectedCategory: any) => {
	let translation: string = "";
	blogCategories.en.forEach((category) => {
		if (category.label === selectedCategory) {
			translation = category.url;
		}
	});
	return translation as BlogURLCategories;
};

export const GetCategoryBackground = (
	selectedCategory: BlogURLCategories,
	language: "en" | "fr" | "es"
) => {
	let translation: any = { imageUrl: "", imagePosition: "" };
	blogCategories[language].forEach((category: BlogCategory) => {
		if (category.url === selectedCategory) {
			translation.imageUrl = category.backgroundImageUrl;
			translation.imagePosition = category.backgroundPosition;
		}
	});
	return translation;
};

export const GetCategoryTitle = (
	selectedCategory: BlogURLCategories,
	language: "en" | "fr" | "es"
) => {
	let translation: string = "";
	blogCategories[language].forEach((category: BlogCategory) => {
		if (category.url === selectedCategory) {
			translation = category.title;
		}
	});
	return translation;
};

export const GetCategoryDescription = (
	selectedCategory: BlogURLCategories,
	language: "en" | "fr" | "es"
) => {
	let translation: string = "";
	blogCategories[language].forEach((category: BlogCategory) => {
		if (category.url === selectedCategory) {
			translation = category.description;
		}
	});
	return translation;
};

export const BlogBadgeColor = (category: BlogURLCategories) => {
	const { theme } = useTheme();
	const categoryAssociations = {
		"": theme.colors.secondary.darkBlue,
		"market-updates": theme.colors.secondary.red,
		"grade-anatomy": theme.colors.secondary.yellow,
		"industry-trends": theme.colors.primary.darkerGray,
		"metal-processing": theme.colors.primary.gray,
		"metal-perspective": theme.colors.secondary.lightBlue,
		"success-stories": theme.colors.secondary.green,
	};
	if (categoryAssociations[category]) return categoryAssociations[category];
	return theme.colors.secondary.lightYellow;
};

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 0px;
			}
		`;
	}}
`;

const Divider = styled.hr`
	margin-top: 0px;
	margin-bottom: 0px;
	opacity: 0.1;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 10px;
`;

const GiantSpacer = styled.div`
	display: block;
	width: 100%;
	height: 270px;
`;

const Left = styled.div`
	display: inline-block;
	width: calc(100% - 300px);
	vertical-align: top;
	padding: 0;
	box-sizing: border-box;
	height: auto;
	border-radius: 2px;
	margin-top: -270px;
	position: relative;
	margin-bottom: 60px;
`;

const Right = styled.div`
	display: inline-block;
	width: 290px;
	margin-top: -270px;
	vertical-align: top;
	margin-left: 10px;
`;

const Social = styled.div`
	width: 20px;
	height: auto;
	left: -36px;
	position: absolute;
`;

const Hero = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	display: block;
	img {
		width: 100%;
		height: auto;
		display: block;
	}
`;

const FlexContainer = styled.div`
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	padding: 40px 44px;
	z-index: 1;
	box-sizing: border-box;
	left: 0;
`;

const RichContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	padding: 50px 0px;
`;

const DownloadSheet = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 10px;
	border-radius: 2px;
	overflow: hidden;
`;

const DownloadSheetImage = styled.div`
	display: block;
	height: auto;
	width: 100%;
	position: relative;
	img {
		width: 100%;
		height: auto;
	}
`;

const DownloadLink = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 15px;
	padding: 0 3px;
	box-sizing: border-box;
`;

const stayTunedParent = "Blog-Article";
const Blog: React.FC<BlogProps> = ({ contentfulContent, content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const [socialReference, setSocialReference] = React.useState<any>(React.createRef());
	const [socialTop, setSocialTop] = React.useState<number>(0);
	React.useEffect(() => {
		if (window !== undefined && document !== undefined) {
			//window.addEventListener("scroll", function () {
			if (socialReference.current) {
				let offset = socialReference.current.getBoundingClientRect();
				let startingTop = offset.top - document.body.getBoundingClientRect().top;
				if (
					window.scrollY + 120 >= startingTop &&
					window.scrollY + 265 < socialReference.current.offsetHeight + startingTop
				) {
					setSocialTop(window.scrollY - startingTop + 120);
				} else if (
					socialReference.current.offsetHeight + startingTop <=
					window.scrollY + 265
				) {
					setSocialTop(socialReference.current.offsetHeight - 145);
				} else {
					setSocialTop(0);
				}
			}
			// });
		}
		if (!socialReference) setSocialReference(React.createRef());
	}, []);

	const [languageContent, setLanguageContent] = React.useState<ContenfulBlogNode>(
		contentfulContent[0].node
	);
	React.useEffect(() => {
		contentfulContent?.forEach((data: ContentfulNode) => {
			if (LanguageLocale[data.node?.node_locale] === language) setLanguageContent(data.node);
		});
	}, [language]);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>

					<Media lessThan="xl">
						<Spacer />
						<Spacer />
						<SocialMedia orientation="horizontal" type="tertiary" />
					</Media>

					<GiantSpacer />
				</ContentSection>
				<ContentSection type="secondary">
					<Left
						ref={socialReference}
						css={css`
							background-color: ${theme.colors.primary.background};
						`}
					>
						<Media greaterThan="lg">
							<Social
								css={css`
									top: ${socialTop + "px"};
								`}
							>
								<SocialMedia orientation="vertical" type="tertiary" />
							</Social>
						</Media>
						<Hero>
							<img src={languageContent?.heroImage?.file?.url} />
							<FlexContainer>
								<Flex
									css={css`
										width: 100%;
										height: 100%;
									`}
									justifyContent="space-between"
									direction="column"
								>
									<FlexItem>
										<Badge
											type="primary"
											color={BlogBadgeColor(
												TranslateBlogCategory(languageContent?.category)
											)}
										>
											{languageContent?.category}
										</Badge>
									</FlexItem>
									<FlexItem>
										<Typography
											variant="h1"
											css={css`
												max-width: 700px;
												margin-top: 0px;
												letter-spacing: -2px;
												margin-bottom: 0px;
											`}
											type="tertiary"
										>
											{languageContent?.title}
										</Typography>
									</FlexItem>
								</Flex>
							</FlexContainer>
						</Hero>
						<RichContainer>
							{languageContent?.subTitle &&
							languageContent.subTitle.subTitle.length > 0 ? (
								<Typography
									css={css`
										margin-bottom: 56px;
										max-width: 560px;
										letter-spacing: -1px;
										margin-top: 0px;
										margin-left: 40px;
										width: calc(100% - 40px);
										font-size: 28px;
										line-height: 30px;
									`}
									type="primary"
									variant="h2"
								>
									{languageContent.subTitle.subTitle}
								</Typography>
							) : (
								<></>
							)}
							<Typography
								variant="div"
								type="primary"
								size="md"
								css={css`
									> p {
										margin-bottom: 18px;
										margin-top: 0px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> ul {
										margin-bottom: 18px;
										margin-top: 0px;
										max-width: 560px;
										margin-left: 40px;
										box-sizing: border-box;
										width: calc(100% - 40px);
									}
									> h2 {
										margin-top: 36px;
										margin-bottom: 42px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> h3 {
										margin-top: 0px;
										margin-bottom: 4px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> h4 {
										margin-top: 0px;
										margin-bottom: 4px;
										max-width: 560px;
										margin-left: 40px;
										width: calc(100% - 40px);
									}
									> img {
										max-width: 100%;
										display: block;
										max-width: calc(100% - 40px);
										margin-left: 40px;
									}
								`}
							>
								{renderBlogRichText(
									languageContent?.body?.raw,
									"primary",
									languageContent?.body?.references
								)}
							</Typography>
						</RichContainer>
					</Left>
					<Right>
						<PopularContent
							title={content.popularHeading}
							excludeSlug={languageContent?.slug}
						/>
						<Spacer
							css={css`
								height: 10px;
							`}
						/>
						<StayTunedComponent
							parent={stayTunedParent}
							parentCategory={TranslateBlogCategory(languageContent?.category)}
							slim={true}
						/>
						<DownloadSheet>
							<DownloadSheetImage>
								<img src="/images/metal-resources/metal-knowledge/NotSure.jpg" />
								<Typography
									variant="div"
									size="sm"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 24px;
										bottom: 24px;
										width: calc(100% - 48px);
										height: auto;
										z-index: 1;
									`}
								>
									{content.ryersonGuideSubTitle}
								</Typography>
							</DownloadSheetImage>
							<DownloadLink>
								<Link to={content.ryersonGuideUrl}>
									<Icon
										icon="file-pdf"
										size="sm"
										color={theme.colors.primary.link}
										css={css`
											display: inline-block;
											vertical-align: top;
										`}
									/>
									<Typography
										variant="h3"
										color={theme.colors.primary.link}
										css={css`
											font-size: 20px;
											line-height: 25px;
											margin-top: 0px;
											margin-bottom: 0px;
											display: inline-block;
											margin-left: 6px;
											letter-spacing: -1px;
											width: calc(100% - 24px);
											vertical-align: top;
										`}
									>
										{content.ryersonGuideTitle}
									</Typography>
								</Link>
							</DownloadLink>
						</DownloadSheet>
					</Right>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary" vPadding="20px">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Spacer />
					<Spacer />
					<Hero>
						<img src={languageContent?.heroImage?.file?.url} />
						<FlexContainer
							css={css`
								padding: 24px 20px;
							`}
						>
							<Badge
								type="primary"
								size="sm"
								color={BlogBadgeColor(
									TranslateBlogCategory(languageContent?.category)
								)}
							>
								{languageContent?.category}
							</Badge>
						</FlexContainer>
					</Hero>
					<Typography
						variant="h3"
						css={css`
							margin-top: 24px;
							letter-spacing: -1px;
							margin-bottom: 16px;
						`}
						type="tertiary"
					>
						{languageContent?.title}
					</Typography>
					<Spacer />
					<SocialMedia orientation="horizontal" type="tertiary" />
					<Spacer />
					<Spacer />
				</ContentSection>
				<ContentSection type="primary" vPadding="32px" hPadding="0px">
					{languageContent?.subTitle && languageContent.subTitle.subTitle.length > 0 ? (
						<Typography
							css={css`
								margin-bottom: 35px;
								letter-spacing: -1px;
								margin-top: 0px;
								font-size: 20px;
								line-height: 24px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
								font-size: 24px !important;
								line-height: 30px !important;
							`}
							type="primary"
							variant="h2"
						>
							{languageContent.subTitle.subTitle}
						</Typography>
					) : (
						<></>
					)}
					<Typography
						variant="div"
						type="primary"
						size="md"
						css={css`
							> ul {
								margin-bottom: 16px;
								margin-top: 0px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> p {
								margin-bottom: 16px;
								margin-top: 0px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> h2 {
								margin-top: 32px;
								margin-bottom: 36px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> h3 {
								margin-top: 0px;
								margin-bottom: 2px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> h4 {
								margin-top: 0px;
								margin-bottom: 2px;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
							> img {
								max-width: 100%;
								display: block;
								margin-left: 20px;
								max-width: calc(100% - 40px);
							}
						`}
					>
						{renderBlogRichText(
							languageContent?.body?.raw,
							"primary",
							languageContent?.body?.references
						)}
					</Typography>

					<ContentSection vPadding="20px" type="primary">
						<SocialMedia
							color={theme.colors.primary.primaryBrand}
							type="primary"
							orientation="horizontal"
						/>
						<Divider
							css={css`
								margin-top: 8px;
								margin-bottom: 56px;
								color: ${theme.colors.primary.darkerGray};
							`}
						/>
						<PopularContent
							title={content.popularHeading}
							excludeSlug={languageContent?.slug}
						/>
						<Spacer
							css={css`
								height: 10px;
							`}
						/>
					</ContentSection>
					<StayTunedComponent
						parent={stayTunedParent}
						parentCategory={TranslateBlogCategory(languageContent?.category)}
					/>
					<ContentSection vPadding="20px" type="primary">
						<DownloadSheet>
							<DownloadSheetImage>
								<img src="/images/metal-resources/metal-knowledge/NotSure.jpg" />
								<Typography
									variant="div"
									size="sm"
									color={theme.colors.primary.gray}
									css={css`
										position: absolute;
										left: 24px;
										bottom: 24px;
										width: calc(100% - 48px);
										height: auto;
										z-index: 1;
									`}
								>
									{content.ryersonGuideSubTitle}
								</Typography>
							</DownloadSheetImage>
							<DownloadLink>
								<Link to={content.ryersonGuideUrl}>
									<Icon
										icon="file-pdf"
										size="sm"
										color={theme.colors.primary.link}
										css={css`
											display: inline-block;
											vertical-align: top;
										`}
									/>
									<Typography
										variant="div"
										size="lg"
										weight="bold"
										color={theme.colors.primary.link}
										css={css`
											margin-top: 0px;
											margin-bottom: 0px;
											display: inline-block;
											margin-left: 6px;
											width: calc(100% - 24px);
											vertical-align: top;
										`}
									>
										{content.ryersonGuideTitle}
									</Typography>
								</Link>
							</DownloadLink>
						</DownloadSheet>
					</ContentSection>
				</ContentSection>
			</Media>
		</>
	);
};

export default Blog;
